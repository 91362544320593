<template>
    <div>
        <div v-if="$store.state.userAgent === 'Mobile'">
            <div v-for="list in lists" class="card-wrap"
             :key="list.tid" @click="handleClick(isDraft ? list.did : list.tid, isDraft)">
                <div class="author" v-if="showAvatar">
                    <img class="avatar" :src="list.avatarUrl" alt="" width="20" height="20">
                    <span>{{ list.nickname }}</span>
                    <!-- <img class="vip" src="../../assets/images/vip-v1.png" alt="" width="14" height="14"> -->
                </div>
                <div :class="showAvatar ? 'title-with-avatar' : 'title'">{{ list.title }}</div>
                <div class="context" v-if="list.articleImg.total === 0">
                    <span>{{ list.context }}</span>
                </div>
                <div class="content" v-else-if="list.articleImg.total === 1">
                    <span>{{ list.context }}</span>
                    <img v-lazy="list.articleImg.img_right" alt="" width="62" height="62">
                </div>
                <div class="contentText" v-else>
                     <span>{{ list.context }}</span>
                     <div class="image">
                        <div class="img">
                            <img v-lazy="list.articleImg.img_left" alt="" width="33%" height="62">
                        </div>
                        <div class="img" style="margin: 0 5px;">
                            <img v-lazy="list.articleImg.img_center" alt="" width="33%" height="62">
                        </div>
                        <div class="img">
                            <img v-lazy="list.articleImg.img_right" alt="" width="33%" height="62">
                        </div>
                     </div>
                </div>
            </div>
        </div>
        <div v-else style="margin-top:20px;">
            <div v-for="list in lists" class="card-wrap-pc"
             :key="list.tid" @click="handleClick(isDraft ? list.did : list.tid, isDraft)">
                <div class="author" v-if="showAvatar">
                    <img :src="list.avatarUrl" alt="" width="20" height="20">
                    <span>{{ list.nickname }}</span>
                </div>
                <div :class="showAvatar ? 'title-with-avatar' : 'title'">{{ list.title }}</div>
                <div class="context" v-if="list.articleImg.total === 0">
                    <span>{{ list.context }}</span>
                </div>
                <div class="content" v-else-if="list.articleImg.total === 1">
                    <span>{{ list.context }}</span>
                    <img v-lazy="list.articleImg.img_right" alt="" width="70" height="70">
                </div>
                <div class="contentText" v-else>
                     <span>{{ list.context }}</span>
                     <div class="image">
                        <div class="img">
                            <img v-lazy="list.articleImg.img_left" alt="" width="33%" height="62">
                        </div>
                        <div class="img" style="margin: 0 5px;">
                            <img v-lazy="list.articleImg.img_center" alt="" width="33%" height="62">
                        </div>
                        <div class="img">
                            <img v-lazy="list.articleImg.img_right" alt="" width="33%" height="62">
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Home',
  created(){
      if(!(/Mobi|Android|iPhone/i.test(navigator.userAgent))){
        this.$store.commit('Set_UserAgent_State', 'PC')
      } else {
        this.$store.commit('Set_UserAgent_State', 'Mobile')
      }
  },
  activated(){
    console.log(this.$store.state.userAgent)
    if(sessionStorage.getItem('fromPath')){
      sessionStorage.removeItem('fromPath')
    }
  },
  components: {
  },
  data(){
    return {
        timeOutEvent:0,
    }
  },
  methods:{
        handleClick(id, isDraft){
            if(!isDraft){
                sessionStorage.setItem('fromPath', this.$route.path)
                this.$router.push({ path:'/article', query:{ tid:id } })
                this.$emit('SaveScrollTop')
            } else {
                this.$router.push({ path:'/create', query:{ did:id } })
            }
        },
  },
  props:{
      lists:{
          type: Array,
          default: ()=>[]
      },
      showAvatar:{
        type: Boolean,
        default: false
      },
      isAuthorPage:{
        type:Boolean,
        default: true
      },
      isDraft:{
        type: Boolean,
        default: false
      }
  }
}
</script>

<style scoped lang="scss">
.card-wrap {
    width: calc(100% - 20px);
    border-radius: 20px;
    margin: 10px;
    // min-height: 120px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid #eee;
    .author {
        text-align: left;
        margin: 10px 0 0 15px;
        display: flex;
        align-items: center;
        .avatar {
            margin-right: 5px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: #ededed;
        }
        span {
            font-size: 14px;
            color: #7a7a7a;
            cursor: default;
        }
        .vip {
            padding-left: 5px;
        }
    }
    .title-with-avatar {
        text-align: left;
        padding: 5px 15px 5px;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
    }
    .title {
        text-align: left;
        padding: 15px 15px 5px;
        font-weight: bold;
        font-size: 16px;
    }
    .context {
        padding: 0px 15px 15px;
        text-align: left;
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 16px;
            cursor: pointer;
        }
    }
    .content {
        display: flex;
        text-align: left;
        align-items: center;
        padding: 0px 15px 15px;
        justify-content: space-between;
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 14px;
            max-width: calc(100% - 77px);
            cursor: pointer;
        }
        img {
            width: 62px;
            height: 62px;
            background: #ededed;
            border-radius: 3px;
        }
    }
    .contentText {
        padding: 0px 15px 15px;
        text-align: left;
        .img {
            width:33%; 
        }
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 14px;
            cursor: pointer;
        }
        .image {
            display: flex;
            margin-top: 5px;
            img {
                width: 100%;
                height: 62px;
                background: #ededed;
                border-radius: 3px;
            }
        }
    }
}
.card-wrap-pc {
    // width: 100%;
    // min-height: 120px;
    // max-width: 625px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid #eee;
    padding: 10px 5px;
    .author {
        text-align: left;
        margin: 5px 15px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            background: #ededed;
        }
        span {
            font-size: 15px;
            color: #7a7a7a;
            cursor: default;
        }
    }
    .title-with-avatar {
        text-align: left;
        padding: 5px 15px 5px;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
    .title {
        text-align: left;
        padding: 15px 15px 5px;
        font-weight: bold;
        font-size: 16px;
    }
    .context {
        padding: 0px 15px 15px;
        text-align: left;
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .content {
        display: flex;
        text-align: left;
        align-items: center;
        padding: 5px 15px 10px;
        justify-content: space-between;
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 15px;
            max-width: calc(100% - 77px);
            cursor: pointer;
            line-height: 23px;
        }
        img {
            width: 70px;
            height: 70px;
            background: #ededed;
            border-radius: 3px;
        }
    }
    .contentText {
        padding: 0px 15px 15px;
        text-align: left;
        .img {
            width:33%; 
        }
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 3;    
            overflow: hidden;
            padding-right: 5px;
            font-size: 14px;
            cursor: pointer;
        }
        .image {
            display: flex;
            margin-top: 5px;
            img {
                width: 100%;
                height: 62px;
                background: #ededed;
                border-radius: 3px;
            }
        }
    }
}

</style>
