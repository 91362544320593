<template>
    <div class="cropper-box" v-show="isShow">
        <div class="header">
            <img src="../../assets/images/back-white.png" alt="" width="30" height="30" @click="show">
            <div @click="handleClick" class="button">保存</div>
        </div>
        <vueCropper ref="cropper" 
            :info="false"
            :img="optionImg"
            :fixed="option.fixed"
            :fixedBox="option.fixedBox"
            :canMove="option.canMove"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :original="option.original"
            :outputSize="option.outputSize"
            :canMoveBox="option.canMoveBox"
            :centerBox="option.centerBox"
            :mode="option.mode"
            @realTime="realTime"
            :outputType="option.outputType">
        </vueCropper>
    </div>
</template>

<script>
export default {
  name: 'Cropper',
  components: {
  },
  activated(){

  },
  data(){
        return {
            isShow: false,
            trueImg:{},
            option:{
                img: '',
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                fixed: false, // 是否开启截图框宽高固定比例
                fixedNumber: [], // 截图框的宽高比例
                fixedBox: true, // 固定截图框大小 不允许改变
                canMove: false, // 上传图片是否可以移动
                centerBox: true, // 截图框是否被限制在图片里面
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth:'max', //默认生成截图框宽度	
                autoCropHeight:'max', //默认生成截图框高度	
                mode:'100% auto'
            },
        }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
        handleClick(){
            this.$refs.cropper.getCropData(base64 => {
                let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
                let context = canvas.getContext('2d') 
                let img = new Image()
                img.src = base64 // 指定图片的DataURL(图片的base64编码数据)
                img.onload = () => {
                    let timestamp = Date.now()
                    // let base64Type = base64.split(';')[0].split(':')[1] // 如'image/png'
                    if(this.uploadType === 'avatar'){
                        // 存原图
                        canvas.width = img.width
                        canvas.height = img.height
                        context.drawImage(img, 0, 0, canvas.width, canvas.height)
                        base64 = canvas.toDataURL('image/jpeg', 0.5) // 0.2为默认压缩质量
                        this.$emit('copperResult', base64, this.uploadType, false, timestamp) // false代表originSize
                        // 存缩略图
                        canvas.width = 85 * 2
                        canvas.height = 85 * 2
                        context.drawImage(img, 0, 0, canvas.width, canvas.height)
                        base64 = canvas.toDataURL('image/jpeg', 0.5) // 0.2为默认压缩质量
                        this.$emit('copperResult', base64, this.uploadType, true, timestamp) // true代表isThumbs
                    } else if(this.uploadType === 'background'){
                        // 存原图
                        canvas.width = img.width
                        canvas.height = img.height
                        context.drawImage(img, 0, 0, canvas.width, canvas.height)
                        base64 = canvas.toDataURL('image/jpeg', 0.5) // 0.2为默认压缩质量
                        this.$emit('copperResult', base64, this.uploadType, false, timestamp) // false代表originSize
                        // 存缩略图
                        canvas.width = 390 * 2
                        canvas.height = 154 * 2
                        context.drawImage(img, 0, 0, canvas.width, canvas.height)
                        base64 = canvas.toDataURL('image/jpeg', 0.5) // 0.2为默认压缩质量
                        this.$emit('copperResult', base64, this.uploadType, true, timestamp) // true代表isThumbs
                    }
                }
            })
        },
        realTime(data){
            this.trueImg.width = data.img.width
            this.trueImg.height = data.img.height
            // 界面渲染完成后再执行
            if(this.isShow){
                    if(this.uploadType === 'background'){
                        this.option.autoCropWidth = window.innerWidth
                        this.option.autoCropHeight = 153 / 390 * window.innerWidth
                    } else if(this.uploadType === 'avatar'){
                        let w = window.innerWidth // 预览图片宽
                        let h = parseInt(this.trueImg.height, 10) / parseInt(this.trueImg.width, 10) * window.innerWidth // 预览图片高
                        let len = w > h ? h : w
                        this.option.autoCropWidth = len
                        this.option.autoCropHeight = len
                    }
            }
        }
  },
  props:{
    optionImg:{
        type: String,
        default:''
    },
    uploadType:{
        type: String,
        default:''
    },
  }
}
</script>

<style scoped lang="scss">
.cropper-box {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    touch-action: none;
    .header {
        display: flex;
        position: absolute;
        top: 0;
        z-index: 1000;
        left: 0;
        right: 0;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px 10px 10px;
        .button {
            color: #ddd;
            font-size: 20px;
        }
    }
}
</style>
