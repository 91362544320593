<template>
    <div v-show="isShow">
        <div class="logout-dialog-box">
            <span>已获得 {{ totalLikeAmount }} 次点赞</span>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'ConfirmData',
  components: {
  },
  data(){
      return {
          isShow: false,
      }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
  },
  props:{
    totalLikeAmount:{
        type: Number || String,
        default: 0
    }
  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.logout-dialog-box {
    width: 80%;
    max-width: 625px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    color: #2c3e50;
    font-weight: bold;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 40%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
