<template>
    <div class="personal-box" id="personal-box">
        <img src="../../assets/images/back-white.png" alt="" v-if="$route.path.includes('author') && $store.state.userAgent === 'Mobile'" class="back-img-author" width="30" height="30" @click="goBack">
        <img src="../../assets/images/more-icon.png" alt="" v-if="$route.path.includes('personal') && $store.state.userAgent === 'Mobile'" class="back-img-personal" width="35" height="35" @click="openPopup">
        <div style="background:#f3f3f3; min-height: 154px;">
            <div v-show="userInfo.BGUrl">
                <img :src="userInfo.BGUrl" alt="" width="100%" height="150px" v-if="isAuthorPage">
                <van-uploader :after-read="(file)=>onRead(file, 'background')" accept="image/*" v-else>
                    <img :src="userInfo.BGUrl" alt="" width="100%" height="150px">
                </van-uploader>
            </div>
        </div>
        <div class="header">
            <div class="avatar" style="background:#f3f3f3; min-height: 85px;">
                <div v-show="userInfo.avatarUrl">
                    <img :src="userInfo.avatarUrl" alt="" width="85px" height="85px" v-if="isAuthorPage">
                    <van-uploader :after-read="(file)=>onRead(file, 'avatar')" accept="image/*" v-else>
                        <img :src="userInfo.avatarUrl" alt="" width="85px" height="85px">
                    </van-uploader>
                </div>
            </div>
            <div class="intro">
                <router-link :to="{ path: '/point-detail', query: { member_point: userInfo.member_point } }" v-if="!isAuthorPage" class="credit-score">
                    <div class="label">
                        <img src="../../assets/images/credit-score.png" alt="" width="21" height="21">
                        积分: {{ userInfo.member_point }}
                    </div>
                </router-link>
                <!-- <div class="button">
                    <router-link to="/edit-personal" v-if="!isAuthorPage">
                        <div class="edit-button">编辑资料</div>
                    </router-link>
                </div> -->
                <div class="nickname">
                    <span>{{ userInfo.nickname }}</span>
                    <div v-show="userInfo.nickname" :style="membershipExpired ? { filter: 'grayscale(100%)' } : ''">
                        <img src="../../assets/images/vip-v1.png" alt="" width="22" height="22" v-show="userInfo.member_level === 'week'">
                        <img src="../../assets/images/vip-v2.png" alt="" width="22" height="22" v-show="userInfo.member_level === 'month'">
                        <img src="../../assets/images/vip-v3.png" alt="" width="22" height="22" v-show="userInfo.member_level === 'quarter'">
                        <img src="../../assets/images/vip-v4.png" alt="" width="22" height="22" v-show="userInfo.member_level === 'year'">
                        <img src="../../assets/images/partner-p2.png" alt="" width="22" height="22" v-show="userInfo.partner_level === 'junior' || userInfo.partner_level === 'senior'">
                    </div>
                </div>
                <div class="interactive">
                    <div class="block">
                        <span class="amount" style="cursor:default;">{{ userInfo.totalPageViews }}</span>
                        <span class="title" style="cursor:default;">阅读数</span>
                    </div>
                    <div class="block" @click="goFansList">
                        <span class="amount">{{ userInfo.fansAmount }}</span>
                        <span class="title">粉丝</span>
                    </div>
                    <div class="block" @click="goFollowList">
                        <span class="amount">{{ userInfo.followAmount }}</span>
                        <span class="title">关注</span>
                    </div>
                    <div class="block" @click="confirmTotalLikeAmount">
                        <span class="amount">{{ userInfo.getLikedTotal }}</span>
                        <span class="title">点赞</span>
                    </div>
                </div>
                <div class="introduct">{{ userInfo.introduct }}</div>
            </div>
        </div>
        <div class="home_wrap">
            <Card :lists="lists" @SaveScrollTop="SaveScrollTop" :isAuthorPage="isAuthorPage"/>
        </div>
        <cropper :optionImg="option.img" :uploadType="option.type" :optionFixedNumber="option.fixedNumber"
                ref="vueCropper" @copperResult="copperResult"/>
        <div :class="$route.path.includes('/personal') && $store.state.userAgent === 'Mobile' ? 'load-more' : 'load-more-bottom'"  v-if="lists.length !== total">正在加载中...</div>
        <div :class="$route.path.includes('/personal') && $store.state.userAgent === 'Mobile' ? 'load-more' : 'load-more-bottom'" v-else v-show="!delayShow">已经到底部啦！</div>
        <login-dialog ref="login" />
        <confirm-data ref="confirmData" :totalLikeAmount="userInfo.getLikedTotal"/>
        <van-popup v-model="showPopup" position="left" :style="{ width: '66%', height: '100%' }">
            <div class="popup-box">
                <div class="row" @click="goNextPage('svip')">
                    <img src="../../assets/images/svip.png" width="22" height="22"/>
                    <span v-if="userInfo.member_level === null || userInfo.member_level.length === 0">开通会员</span>
                    <span v-else>会员续费</span>
                </div>
                <!-- <div class="row" @click="goNextPage('wallet')">
                    <img src="../../assets/images/wallet.png" width="22" height="22"/>
                    <span>我的钱包</span>
                </div> -->
                <div class="row" @click="goNextPage('coupon')">
                    <img src="../../assets/images/coupon.png" width="22" height="22"/>
                    <span>我的卡券</span>
                </div>
                <div class="row" @click="goNextPage('order')">
                    <img src="../../assets/images/order.png" alt="" width="22" height="22" />
                    <span>我的订单</span>
                </div>
                <div class="row" @click="goNextPage('edit')">
                    <img src="../../assets/images/edit.png" width="22" height="22" />
                    <span>编辑资料</span>
                </div>
                <div class="row" @click="goNextPage('create')">
                    <img src="../../assets/images/create-plain.png" width="22" height="22" />
                    <span>创建文章</span>
                </div>
                <div class="row" @click="goNextPage('draft')">
                    <img src="../../assets/images/draft-box.png" width="22" height="22" />
                    <span>我的草稿</span>
                </div>
                <div class="row" @click="goNextPage('address')">
                    <img src="../../assets/images/address.png" alt="" width="22" height="22" />
                    <span>地址管理</span>
                </div>
                <div class="row" @click="goNextPage('booking')" v-show="appName === '应景导航'">
                    <img src="../../assets/images/booking.png" alt="" width="22" height="22" />
                    <span>产品预购</span>
                </div>
                <div class="row" @click="goNextPage('download')">
                    <img src="../../assets/images/admin-plain.png" alt="" width="22" height="22" />
                    <span>下载Apk</span>
                </div>
                <div class="row" @click="goNextPage('partnerAdmin')"  v-if="userInfo.partner_level === 'junior' || userInfo.partner_level === 'senior'">
                    <img src="../../assets/images/partner.png" alt="" width="22" height="22" />
                    <span>合伙人后台</span>
                </div>
                <div class="row" @click="goNextPage('partner')" v-else>
                    <img src="../../assets/images/partner.png" alt="" width="22" height="22" />
                    <span>成为合伙人</span>
                </div>
                <div class="row" @click="goNextPage('about')">
                    <img src="../../assets/images/about.png" alt="" width="21" height="21" />
                    <span>关于{{ appName }}</span>
                </div>
                <div class="row" @click="logout">
                    <img src="../../assets/images/logout.png" alt="" width="22" height="22" />
                    <span>退出登录</span>
                </div>
            </div>
        </van-popup>
        <Main v-if="$route.path.includes('personal') && $store.state.userAgent === 'Mobile'" @resetScrollTop="resetScrollTop"></Main>
        <logout ref="logout" />
        <login-reminder ref="loginReminder" style="bottom:0;"/>
    </div>
</template>

<script>
import Card from '../../components/card/index.vue'
import { queryAllArticleByUid, queryUserByPhoneNum, upload, deleteUpload, updateAvatarUrl,updateBGUrl, queryUserByUid, wxSdk, getOpenId  } from '@/api/api'
import Cropper from './Cropper.vue';
import LoginDialog from '../../components/dialog/LoginDialog.vue';
import wx from "weixin-js-sdk";
import Main from '../../components/footer/Main.vue';
import ConfirmData from '../../components/dialog/ConfirmData.vue';
import Logout from '../../components/dialog/Logout.vue';
import LoginReminder from '../../components/dialog/LoginReminder.vue';
import moment from 'moment';
const seo = require('../../../config/index')

export default {
    name: 'Personal',
    components: {
        Main,
        Card,
        Logout,
        Cropper,
        LoginDialog,
        ConfirmData,
        LoginReminder,
    },
    mounted(){
        document.getElementById("personal-box").style.cssText= 'min-height:' + String(document.documentElement.clientHeight) + 'px' ;
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("personal-box").style.cssText= 'margin-top: 58px';
        }
        // 获取openid
        this.getopenIdByCode()
    },
    deactivated() {
        document.removeEventListener('scroll', this.handleScroll);
        if(!this.isAuthorPage){
            window.removeEventListener("popstate", this.myBack, false); 
        }
    },
    data:()=>{
        return {
            userInfo: {
                nickname: '',
                introduct: '',
                avatarUrl: '',
                BGUrl: '',
                fansAmount: 0,
                followAmount: 0,
                getLikedTotal:0,
                totalPageViews:0,
                member_level: '',
                partner_level: '',
                member_point: '',
            },
            option:{
                img:'',
                type:'',
                fixedNumber:[],
            },
            uid: 1,
            lists:[],
            scroll: 0,
            total: 0,
            limit: 10,
            isEnd: false,
            showPopup: false,
            appName: seo.config.appName,
            delayShow: false, // 用户体验优化
            isAuthorPage: true, // 区分用户中心还是个人中心
            isLogin: localStorage.getItem('token') ? true : false,
            membershipExpired: false, // 会员是否已过期，默认在有效期内
        }
    },
    methods:{
        getUserInfo(){
            if(this.$route.path === '/personal'){
                // 请求个人信息，并更新到本地
                this.$store.commit('Set_Loading_State', true)
                queryUserByPhoneNum({ phoneNum: localStorage.getItem('phoneNumber') }).then(res=>{
                    localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                    this.userInfo.nickname = JSON.parse(localStorage.getItem('userInfo'))[0].nickname
                    this.userInfo.introduct = JSON.parse(localStorage.getItem('userInfo'))[0].intro
                    let wxShareImgUrl
                    if(JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl){
                        this.userInfo.avatarUrl = JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl
                        wxShareImgUrl = seo.config.wxConfig.personalPage.link + JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl
                    } else {
                        this.userInfo.avatarUrl = require('../../assets/images/avatar.png')
                        wxShareImgUrl = seo.config.wxConfig.personalPage.imgUrl
                    }
                    if(JSON.parse(localStorage.getItem('userInfo'))[0].miniBackgroundUrl){
                        this.userInfo.BGUrl = JSON.parse(localStorage.getItem('userInfo'))[0].miniBackgroundUrl
                    } else {
                        this.userInfo.BGUrl = require('../../assets/images/person-bg.jpg')
                    }
                    this.userInfo.followAmount = res.data.data[0].followAmount
                    this.userInfo.fansAmount = res.data.data[0].fansAmount
                    this.userInfo.getLikedTotal = res.data.data[0].getLikedTotal
                    this.userInfo.totalPageViews = res.data.data[0].totalPageViews
                    this.userInfo.member_point = res.data.data[0].member_point
                    this.userInfo.member_level = res.data.data[0].member_level
                    this.userInfo.partner_level = res.data.data[0].partner_level
                    // 判断会员是否过期
                    const currentDate = moment();
                    const expirationDate = moment(res.data.data[0].membership_expiry);
                    this.membershipExpired = currentDate.isAfter(expirationDate) ? true : false
                    this.wxShare(wxShareImgUrl)
                    // seo
                    document.title = this.userInfo.nickname + seo.config.seoPage.personalPage.title.split('#')[1]
                    let keywords = seo.config.seoPage.personalPage.keywords
                    let description = '欢迎来到'+ this.userInfo.nickname + seo.config.seoPage.personalPage.description.split('#')[1]
                    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
                    document.querySelector('meta[name="description"]').setAttribute('content', description)
                    setTimeout(()=>{
                        this.$store.commit('Set_Loading_State', false)
                    },300)
                })
            } else if(this.$route.path === '/author'){
                this.$store.commit('Set_Loading_State', true)
                queryUserByUid({uid: this.$route.query.uid}).then((res)=>{
                    this.userInfo.nickname = res.data.data.nickname
                    this.userInfo.introduct = res.data.data.intro
                    let wxShareImgUrl
                    if(res.data.data.miniAvatarUrl){
                        this.userInfo.avatarUrl = res.data.data.miniAvatarUrl
                        wxShareImgUrl = seo.config.wxConfig.personalPage.link + res.data.data.miniAvatarUrl
                    } else {
                        this.userInfo.avatarUrl = require('../../assets/images/avatar.png')
                        wxShareImgUrl = seo.config.wxConfig.personalPage.imgUrl
                    }
                    if(res.data.data.miniBackgroundUrl){
                        this.userInfo.BGUrl = res.data.data.miniBackgroundUrl
                    } else {
                        this.userInfo.BGUrl = require('../../assets/images/person-bg.jpg')
                    }
                    this.userInfo.followAmount = res.data.data.followAmount
                    this.userInfo.fansAmount = res.data.data.fansAmount
                    this.userInfo.getLikedTotal = res.data.data.getLikedTotal
                    this.userInfo.totalPageViews = res.data.data.totalPageViews
                    this.userInfo.member_level = res.data.data.member_level
                    this.userInfo.partner_level = res.data.data.partner_level
                    // 判断会员是否过期
                    const currentDate = moment();
                    const expirationDate = moment(res.data.data.membership_expiry);
                    this.membershipExpired = currentDate.isAfter(expirationDate) ? true : false
                    this.wxShare(wxShareImgUrl)
                    // seo
                    document.title = res.data.data.nickname + seo.config.seoPage.personalPage.title.split('#')[1]
                    let keywords = seo.config.seoPage.personalPage.keywords
                    let description = '欢迎来到'+ res.data.data.nickname + seo.config.seoPage.personalPage.description.split('#')[1]
                    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
                    document.querySelector('meta[name="description"]').setAttribute('content', description)
                    setTimeout(()=>{
                        this.$store.commit('Set_Loading_State', false)
                    },300)
                })
            }
        },
        getData(limit){
            this.delayShow = true
            let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
            this.uid =  this.$route.query.uid ? this.$route.query.uid : uidTemp
            if(this.limit === 10){
                this.$store.commit('Set_Loading_State', true)
            }
            queryAllArticleByUid({ limit: limit, uid:this.uid }).then((res)=>{
                let tempArr = []
                this.total = res.data.data.count //数据库中返回的所有文章总数
                res.data.data.data.forEach((item)=>{
                let temp = {}
                temp.tid = item.tid
                temp.title = item.title
                temp.context = item.context
                // 图片显示前三张
                let imgUrlArr = item.miniImgUrl ? item.miniImgUrl.split(',') : []
                temp.articleImg = {}
                if(this.$store.state.userAgent === 'Mobile'){
                    if(imgUrlArr.length === 1 || imgUrlArr.length === 2){
                        temp.articleImg.img_right = imgUrlArr[0]
                        temp.articleImg.total = 1 // 显示1张
                    } else if(imgUrlArr.length >= 3){
                        temp.articleImg.img_right = imgUrlArr[2]
                        temp.articleImg.img_center = imgUrlArr[1]
                        temp.articleImg.img_left = imgUrlArr[0]
                        temp.articleImg.total = 3 // 显示3张
                    } else {
                        temp.articleImg.total = 0 // 不显示图片
                    }
                } else {
                    if(imgUrlArr.length > 0){
                        temp.articleImg.img_right = imgUrlArr[0]
                        temp.articleImg.total = 1 // 显示1张
                    } else {
                        temp.articleImg.total = 0 // 不显示图片
                    }
                }
                tempArr.push(temp)
                })
                this.lists = tempArr
                setTimeout(()=>{
                    if(this.limit === 10){
                        this.$store.commit('Set_Loading_State', false)
                    }
                },300)
                setTimeout(()=>{
                    this.delayShow = false
                },300)
            })
        },
        // 无限滚动加载页面
        handleScroll() {
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight // 可视区的高度
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //dom元素的高度，包含溢出不可见的内容
            //vue中获取滚动条到底部的距离
            let scrollBottom = scrollHeight - scrollTop - windowHeight
            //以下三个条件不执行数据加载：1.数据正在加载的状态，2.已经到底了，3.滚动条距离底部的距离小于100px
            if (!this.$store.state.loading && !this.isEnd && scrollBottom < 100 && this.lists.length < this.total) {
                this.limit += 10
                this.isEnd = true
                this.getData(this.limit)
            } else {
                this.isEnd = false
            }
        },
        onRead(file, type) {
            // 个人中心图片质量不用太高，超200KB进行压缩
            if(/\/(?:jpeg|png)/i.test(file.file.type)&&file.file.size>200000){
                let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
                let context = canvas.getContext('2d') 
                let img = new Image()
                img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
                img.onload = () => {
                    // 指定canvas画布大小，该大小为最后生成图片的大小
                    canvas.width = img.width
                    canvas.height = img.height
                    context.drawImage(img, 0, 0, img.width, img.height)
                    file.content = canvas.toDataURL(file.file.type, 0.3) // 个人中心图片质量不用太高'image/jpeg'
                    // 裁剪图片
                    this.option.img = file.content
                    this.option.type = type
                    this.option.fixedNumber = type === 'avatar' ? [1,1] : [3,1]
                    this.$refs.vueCropper.show()
                }
            } else {
                // 裁剪图片
                this.option.img = file.content
                this.option.type = type
                this.option.fixedNumber = type === 'avatar' ? [1,1] : [3,1]
                this.$refs.vueCropper.show()
            }
        },
        // 上传图片到服务器
        uploadImg(imgBase64, type, isThumbs, timestamp){
            let imgUrl
            if(!isThumbs){ // 在上传原图时执行一次删除
                // 删除已上传的头像
                if(type === 'avatar'){
                    imgUrl = JSON.parse(localStorage.getItem('userInfo'))[0].avatarUrl
                    this.userInfo.avatarUrl = require('../../assets/images/avatar.png')
                } else if(type === 'background'){
                    imgUrl = JSON.parse(localStorage.getItem('userInfo'))[0].backgroundUrl
                    this.userInfo.BGUrl = require('../../assets/images/person-bg.jpg')
                }
                if(imgUrl.includes('/public')){
                    deleteUpload({ imgUrl: imgUrl }).then(()=>{
                        console.log('删除成功')
                    })
                }
            }
            // 上传新头像、上传新背景
            upload({ imgBase64: imgBase64, isThumbs, timestamp }).then((res) => {
                if(type === 'avatar'){
                    updateAvatarUrl({ uid: this.uid, avatarUrl: res.data.data.imgUrl, isThumbs: isThumbs }).then(()=>{
                        this.$store.dispatch('getUserInfo')
                        this.userInfo.avatarUrl = res.data.data.imgUrl
                        console.log('更新成功')
                    })
                } else if(type === 'background') {
                    updateBGUrl({ uid: this.uid, BGUrl: res.data.data.imgUrl, isThumbs: isThumbs  }).then(()=>{
                        this.$store.dispatch('getUserInfo')
                        this.userInfo.BGUrl = res.data.data.imgUrl
                        console.log('更新成功')
                    })
                }
            }).catch((error) => {
                console.warn(error)
            })
            if(isThumbs){
                this.$refs.vueCropper.show()
            }
        },
        SaveScrollTop(){
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
        },
        goBack(){
            if(this.$route.path === '/author'){
                if(this.$route.query.share){
                    this.$router.push('/')
                } else {
                    this.$router.back()
                }
            } else {
                this.$router.push('/search')
            }
        },
        goNextPage(id){
            this.showPopup = false
            setTimeout(()=>{
                if(id === 'draft'){
                    this.$router.push('/draft-box')
                } else if(id === 'order'){
                    sessionStorage.setItem('fromPath', '/personal')
                    this.$router.push({ path: '/order' })
                } else if(id === 'create'){
                    this.$router.push('/create')
                } else if(id === 'booking'){
                    this.$router.push('/booking')
                } else if(id === 'admin'){
                    window.location.href='https://admin.emer-nav.cn'
                } else if(id === 'about'){
                    this.$router.push('/about')
                } else if(id === 'edit'){
                    this.$router.push('/edit-personal')
                } else if(id === 'address'){
                    this.$router.push({ path: '/address', query:{ from:'personal' } })
                } else if(id === 'download'){
                    this.$router.push('/download')
                } else if(id === 'svip'){
                    this.$router.push('/activesvip')
                } else if(id === 'coupon'){
                    this.$router.push('/coupon')
                } else if(id === 'wallet'){
                    this.$router.push('/wallet')
                } else if(id === 'partner'){
                    this.$router.push('/partner')
                } else if(id === 'partnerAdmin'){
                    this.$router.push('/partner-admin')
                }
            },0)
        },
        logout(){
            this.showPopup = false
            setTimeout(()=>{
                this.$refs.logout.show()
            },0)
        },
        openPopup(){
            this.showPopup = true
        },
        resetUserInfo(){
            this.userInfo.nickname = ''
            this.userInfo.introduct = ''
            this.userInfo.avatarUrl = ''
            this.userInfo.BGUrl = ''
        },
        isAuthor(){
            let isLogin = localStorage.getItem('token') ?  localStorage.getItem('token') : ''//校验登录态
            if(this.$route.path==='/author'){
                if( isLogin && this.$route.query.uid === String(JSON.parse(localStorage.getItem('userInfo'))[0].uid)){
                    this.isAuthorPage = false
                } else {
                    this.isAuthorPage = true
                }
            } else {
                history.pushState(null, null, document.URL); 
                window.addEventListener("popstate", this.myBack, false);
                this.isAuthorPage = false
            }
        },
        copperResult(copperBase64, type, isThumbs, timestamp){
            this.fileContent = copperBase64
            this.uploadImg(copperBase64, type, isThumbs, timestamp)
        },
        goFansList(){
            this.isLogin = localStorage.getItem('token') ? true : false
            if(this.isLogin){                
                let uid = this.isAuthorPage ?  this.$route.query.uid : JSON.parse(localStorage.getItem('userInfo'))[0].uid
                this.$router.push({ path:'/list',query:{ q:'fans', uid: uid }})
            } else {
                // 弹窗跳转到登录页
                this.$refs.login.show()
            }
        },
        goFollowList(){
            this.isLogin = localStorage.getItem('token') ? true : false
            if(this.isLogin){
                let uid = this.isAuthorPage ?  this.$route.query.uid : JSON.parse(localStorage.getItem('userInfo'))[0].uid
                this.$router.push({ path:'/list',query:{ q:'follow', uid: uid }  })
            } else {
                // 弹窗跳转到登录页
                this.$refs.login.show()
            }
        },
        confirmTotalLikeAmount(){
            this.$refs.confirmData.isShow = true
        },
        myBack() {
            history.pushState(null, null, document.URL);
        },
        wxShare(wxShareImgUrl){
            let url = ''
            if(this.$route.fullPath.includes('/personal')){
                url = seo.config.wxConfig.personalPage.link + this.$route.fullPath + '?share=wx'
            } else if(this.$route.fullPath.includes('/author')){
                url = seo.config.wxConfig.personalPage.link + this.$route.fullPath + '&share=wx'
            }
            let title = this.userInfo.nickname + '的个人主页'
            wxSdk({ url:url }).then(res=>{
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.data.signature,// 必填，签名
                    jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                    success: function(res) {
                    // “分享给朋友”及“分享到QQ”
                    if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                        wx.updateAppMessageShareData({ 
                            title: title, // 分享标题
                            desc: '快来和我一起参与媒辅助社区吧~', // 分享描述
                            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                            imgUrl: wxShareImgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                    }
                    // “分享到朋友圈”及“分享到 QQ 空间”
                    if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                        wx.updateTimelineShareData({ 
                            title: title, // 分享标题
                            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                            imgUrl: wxShareImgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                    }
                }})
                })
            })
        },
        resetScrollTop(){
            this.scroll = 0
            document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
            document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
        },
        getopenIdByCode(){
            if(window.location.href.includes('code=')){
                let code = window.location.href.split('code=')[1].split('&')[0]
                getOpenId({ code: code }).then(res=>{
                    localStorage.setItem('openid', res.data.openid)
                })
            }
        }
    },
    watch:{
        '$route': function(newVal, oldVal){
            let fromPath = sessionStorage.getItem('fromPath')
            this.isLogin = localStorage.getItem('token') ? true : false
            // 文章详情页跳回个人中心/作者中心使用缓存，不重新getData
            if(oldVal.path === '/edit-personal' || !(fromPath && fromPath.includes('/personal')
                || fromPath && sessionStorage.getItem('fromPath').includes('/author'))){
                // 更新个人中心数据
                if(oldVal.path !== '/personal' && newVal.path !== '/personal'){
                    this.lists = []
                }
                this.$nextTick(()=>{
                    this.getData(this.limit)
                    if(oldVal.path !== '/personal' && newVal.path !== '/personal'){
                        this.resetUserInfo()
                    }
                    this.getUserInfo()
                })
            }
        },
    },
    created(){
        this.getData(this.limit)
        this.resetUserInfo()
        this.getUserInfo()
    },
    activated(){
        this.isAuthor()
        this.isLogin = localStorage.getItem('token') ? true : false
        if(!this.isLogin){
            this.$refs.loginReminder.show()
        } else {
            this.$refs.loginReminder.close()
        }
        // 从文章详情返回首页，滚动到上次位置
        setTimeout(() => {
            document.addEventListener('scroll',this.handleScroll);
            document.documentElement.scrollTop = this.scroll
            document.body.scrollTop = this.scroll
        }, 0)
    }
}
</script>

<style scoped lang="scss">
.personal-box {
    max-width: 625px;
    margin: 0 auto;
    position: relative;
    .back-img-author {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        background: rgba(250, 250, 250, 0.5);
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 6px 4px;
        box-sizing: border-box;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .back-img-personal {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        background: rgba(64, 34, 0, 0.2);
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 6px 4px;
        box-sizing: border-box;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .header {
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .avatar {
            width: 85px;
            height: 85px;
            position: absolute;
            background: #FFF;
            border-radius: 50%;
            z-index: 1;
            top: 110px;
            margin: 0 0 0 20px;
            img {
                border-radius: 50%;
            }
        }
        .intro {
            padding-bottom: 20px;
            background: #fff;
            position: relative;
            .credit-score {
                width:100%;
                .label {
                    background: rgba(64, 34, 0, 0.2);
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    padding: 0 15px;
                    position: absolute;
                    right: 0px;
                    top: 6px;
                    height: 35px;
                    color: rgba(64, 34, 0, 1);
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        padding-right: 5px;
                    }
                }
            }
            .button {
                width:100%;
                .edit-button {
                    border-radius: 5px;
                    border: 1px solid #969699;
                    width: 100px;
                    padding: 4px 0;
                    position: absolute;
                    right: 20px;
                    top: 6px;
                }
                a {
                    text-decoration:none ; 
                    color: #0f0f0f;
                }
                .router-link-active {
                    text-decoration: none ;
                    color: #0f0f0f;
                }
            }
            .nickname {
                font-size: 22px;
                line-height: 20px;
                font-weight: 500;
                text-align: left;
                min-height: 30px;
                padding: 55px 0 5px 20px;
                display: flex;
                img {
                    padding-left: 5px;
                }
            }
            .interactive {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;
                margin: 0 0 0 20px;
                .block {
                    display: flex;
                    align-items: center;
                }
                div {
                    margin-right: 30px;
                    display: flex;
                    flex-wrap: nowrap;
                    box-sizing: border-box;
                    .title {
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .amount {
                        margin-right: 4px;
                        font-weight: bold;
                        max-width: 40px;
                        overflow: hidden;
                        cursor: pointer;
                    }
                }
            }
            .introduct {
                text-align: left;
                font-size: 14px;
                margin: 10px 20px 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;    
                -webkit-line-clamp: 3;    
                overflow: hidden;
                min-height: 20px;
            }
        }
    }
    .home_wrap {
        width: 100%;
        margin: 15px 0 0;
    }
    .popup-box {
        margin: 65px 0 40px;
        font-size: 16px;
        .row {
            height: 55px;
            line-height: 55px;
            display: flex;
            margin: 0 20px;
            align-items: center;
            border-bottom: 1px rgba(0,0,0,0.05) solid;
            img {
                padding-right: 10px;
            }
        }
    }
    .load-more {
        text-align: center;
        color: #969699;
        margin-bottom: calc(65px + constant(safe-area-inset-bottom));
        margin-bottom: calc(65px + env(safe-area-inset-bottom));
        font-size: 14px;
    }
    .load-more-bottom {
        text-align: center;
        color: #969699;
        padding-bottom: calc(10px + constant(safe-area-inset-bottom));
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
        font-size: 14px;
    }
}
.van-uploader {
    position: relative;
    display: inline-block;
    width: 100%;
}
::v-deep .van-uploader__input-wrapper {
    position: relative;
    width: 100%;
}
// ::v-deep .van-uploader__input {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 90%;
//     height: 80%;
//     overflow: hidden;
//     cursor: pointer;
//     opacity: 0;
// }
</style>